<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Comisiones Cargadas</h1>
            <div class="info-card" v-if="sale_point.id">
                <p>Datos generales</p>
                <hr>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <div class="row mb-2">
                            <div class="col-md-6"><p>Nombre</p></div>
                            <div class="col-md-6"><b>{{sale_point.name}}</b></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-6"><p>Total Comisión</p></div>
                            <div class="col-md-6"><b>${{formatMoney(sale_point.total_com)[0]}},<label style="font-size: .8rem;">{{formatMoney(sale_point.total_com)[1]}}</label></b></div>
                        </div>
                        <div class="row mb-2" style="text-decoration: underline; cursor: pointer" @click="showPayments()">
                            <div class="col-md-6"><p>Total Pagos</p></div>
                            <div class="col-md-6"><b>${{formatMoney(sale_point.payments)[0]}},<label style="font-size: .8rem;">{{formatMoney(sale_point.payments)[1]}}</label></b></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-6"><p>Pagos Pendientes</p></div>
                            <div class="col-md-6"><b>${{formatMoney(sale_point.pending_payments)[0]}},<label style="font-size: .8rem;">{{formatMoney(sale_point.pending_payments)[1]}}</label></b></div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="accordion" id="accordion" style="max-height: 200px; overflow-y: scroll;">
                            <div class="card" v-for="(year, i) in commissions" :key="year.year">
                                <div class="card-header" :id="`heading-${i}`">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" 
                                    data-toggle="collapse" :data-target="`#collapse-${i}`" aria-expanded="false" 
                                    :aria-controls="`collapse-${i}`">
                                    <p><strong>{{year.year}} | $ {{formatMoney(year.money)[0]}} </strong></p>
                                    </button>
                                </h2>
                                </div>
                                <div :id="`collapse-${i}`" class="collapse" :aria-labelledby="`heading-${i}`" data-parent="#accordion">
                                <div class="card-body" style="padding: .5rem .5rem">
                                    <div class="accordion-body" v-for="month in year.months" :key="month.month"
                                    style="padding: .3rem 1.5rem;">
                                        <div class="row" style="font-size: .9rem;">
                                            <div class="col">{{formatMonth(month.month)}}</div>
                                            <div class="col">${{formatMoney(month.money)[0]}}</div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button v-if="session_user.pages.aliance_createpayment"
                        class="btn btn-danger" style="width: 100%;" 
                        @click="showModalPayment()">Realizar Pago</button>
                    </div>
                </div>
            </div>
            <div class="info-card" v-else>
                <div style="display: flex; justify-content: center; ">
                    <div class="lds-dual-ring"></div>
                </div>
            </div>
            <div class="info-card">
                <p>Últimas comisiones</p>
                <hr>
                <table class="table table-stripped" id="last_comm_table">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Documento</th>
                            <th>Monto</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </main>
        <Right/>
        <!------------MODAL COMS------------>
        <div class="modal fade" id="modal-payment" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title"><p>Cancelar Comisión</p></h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-4"><p>Código</p></div>
                            <div class="col-8">{{sale_point.name}}</div>
                            <div class="col-4"><p>Pagos Pendientes</p></div>
                            <div class="col-8"><b>${{formatMoney(sale_point.pending_payments)[0]}},<label style="font-size: .8rem;">{{formatMoney(sale_point.pending_payments)[1]}}</label></b></div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-8">
                                <input type="number" class="form-control" v-model="payment" placeholder="$">
                            </div>
                            <div class="col-4">
                                <button class="btn btn-danger" style="width:100%;" @click="payCommission()">Pagar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--------------MODAL PAGOS----------------->
        <div class="modal fade" id="modal-payments" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">
                            PAGOS REALIZADOS
                        </h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-striped" id="table-payments" style="border:none">
                            <thead>
                                <tr>
                                    <th>Pago</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { mapMutations, mapActions, mapState } from 'vuex';
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';
export default {
    name: 'CommissionsCharged',
    components: {
        Aside,
        Right,
    },
    data: function(){
        return {
            payment: '',
            sales: [],
        }
    },
    methods: {
        payCommission(){
            if(this.payment <= 0) return this.launchAlert({type: 'warning', title: 'Digita un valor válido'});
            if(this.payment > this.sale_point.pending_payments) return this.launchAlert({type: 'warning', title: 'Valor digitado supera la deuda del punto'});
            this.$swal.fire({
                title: '¿Estás seguro que deseas realizar este pago?',
                html: `Realizar pago <b>$${this.formatMoney(this.payment)[0]},<label style="font-size: .9rem;">${this.formatMoney(this.payment)[1]}</label></b> 
                a ${this.sale_point.name}`,
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = this.api_url+`/api/salepoints/${this.sale_point.id}/payment`;
                    axios.post(url, {money: this.payment},
                    { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
                    .then(res => {
                        if(res.status == 201) {
                            this.payment = '';
                            $('#modal-payment').modal('hide');
                            this.loadSalePoint(this.$route.params.id);
                            return this.launchAlert({type: 'success', title: 'Pago realizado exitosamente'});
                        }
                    })
                }
            })
        },
        showPayments(){
            $('#modal-payments').modal('show');
            this.searchPayments({id: this.sale_point.id}).then(res => {
                let new_array = [];
                res.data.forEach(element => {
                    let commission = `$${this.formatMoney(element.money)[0]},<label style="font-size: .8rem;">${this.formatMoney(element.money)[1]}</label>`;
                    let date = this.formatHourDate(element.created_at);
                    let json = {
                        commission: commission,
                        created_at: date
                    }
                    new_array.push(json);
                });
                $('#table-payments').DataTable({
                    responsive: true,
                    autoWidth: false,
                    lengthChange: false,
                    info: false,
                    destroy: true,
                    data: new_array,
                    columns: [
                        {"data": "commission"},
                        {"data": "created_at"},
                    ]
                });
            })
        },  
        
        searchSales(search){
            let url = this.api_url+'/api/search/sales';
            axios.post(url, search, 
            { headers: { "Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(res => {
                this.sales = res.data;
                this.loadTableCommInfo(this.sales);
            }).catch(e=> console.log(e.response))
        },
        loadTableCommInfo(info){
            let new_array = [];
            info.forEach(element => {
                let commission = `$${this.formatMoney(element.commission)[0]},<label style="font-size: .8rem;">${this.formatMoney(element.commission)[1]}</label>`;
                let date = this.formatDate(element.created_at);
                let json = {
                    client_user_id: element.client_user_id,
                    client_user_name: element.client_user_name,
                    client_user_id_card: element.client_user_id_card,
                    commission: commission,
                    created_at: date
                }
                new_array.push(json);
            });
            $('#last_comm_table').DataTable({
                autoWidth: false,
                responsive: true,
                lengthChange: false,
                info: false,
                destroy: true,
                data: new_array,
                columns: [
                    {"data": "client_user_id"},
                    {"data": "client_user_name"},
                    {"data": "client_user_id_card"},
                    {"data": "commission"},
                    {"data": "created_at"},
                ]
            });
        },

        formatMonth(month){
            let name = '';
            switch (month) {
                case 1:name = 'Enero';break;
                case 2:name = 'Febrero';break;
                case 3:name = 'Marzo';break;
                case 4:name = 'Abril';break;
                case 5:name = 'Mayo';break;
                case 6:name = 'Junio';break;
                case 7:name = 'Julio';break;
                case 8:name = 'Agosto';break;
                case 9:name = 'Septiembre';break;
                case 10:name = 'Octubre';break;
                case 11:name = 'Noviembre';break;
                case 12:name = 'Diciembre';break;
            
            }
            return name;
        },
        formatMoney(number){
            let money = new Intl.NumberFormat('em-IN').format(number);
            let split = money.split(',');
            let decimals = split[1];
            if(!decimals) decimals = '00';
            return [split[0], decimals];
        },
        formatHourDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            var horas = fecha.getHours();
            var minutos = fecha.getMinutes();

            if(mes < 10){
                mes="0"+mes
            }
            if(dia < 10 ){
                dia="0"+dia
            }
            return `${ano}-${mes}-${dia} ${horas}:${minutos}`;
        },
        formatDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            var horas = fecha.getHours();
            var minutos = fecha.getMinutes();

            if(mes < 10){
                mes="0"+mes
            }
            if(dia < 10 ){
                dia="0"+dia
            }
            return `${ano}-${mes}-${dia} ${horas}:${minutos}`;
        },
        launchAlert(config){
            if(!config.timeout) config.timeout = 2500;
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: config.timeout,
            })
            Toast.fire({
                icon: config.type,
                title: config.title,
                text: config.message,
            })
        },
        showModalPayment(){$('#modal-payment').modal('show')},
        ...mapActions("user", ["getUsersLine"]),
        ...mapActions("sale_point", ["searchPayments"]),
        ...mapMutations(['loadSessionUser']),
        ...mapMutations("sale_point", ["loadSalePoint"]),
        ...mapMutations("sale_point", ["loadCommissions"]),
    },
    mounted: function(){
        $('.modal-backdrop').remove();
        this.loadSessionUser();
        this.loadSalePoint(this.$route.params.id);
        this.loadCommissions(this.$route.params.id);
        this.searchSales({sale_point: this.$route.params.id});        
    },
    computed: {
        ...mapState(['api_url']),
        ...mapState(['session_user']),
        ...mapState('sale_point', ['sale_point']),
        ...mapState('sale_point', ['commissions']),
    }
}
</script>